 <template>
  <v-row class="rowDialog">
    <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="430" class="dialog">
      <v-card class="contentCard">
        <v-card-title class="headline">
          <v-col cols="10" class="modal-title pb-0">
            Conta Digital
          </v-col>
          <v-col class="icon pb-1" cols="1">
            <v-icon  @click="closeModal" class="close-icon">
              mdi-window-close
            </v-icon>
          </v-col>
          <v-divider  class="mx-4"></v-divider>
        </v-card-title>
        <v-card-text class="modal-form mt-2 mt-md-0" v-if="!getPasswordCreated">
          <v-row justify="center" class="success-text">
            <v-col cols="12" md="11" class="mt-0">
              <v-row justify="center">
                <v-col cols="12" class="mt-0">
                  <span class="title-welcome">Seja bem-vindo à Finago!</span>
                  <p class="text-welcome mt-5">
                    Estamos quase lá. Para começar a utilizar sua Conta Digital,
                    você deve criar abaixo uma senha para autenticar suas transações.
                  </p>
                </v-col>
                <v-col cols="12">
                  <span class="title-password">Senha Conta Digital</span>
                </v-col>
                <v-form
                 @submit.prevent="submit"
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                <v-col cols="12" md="12">
                  <v-row>
                    <v-text-field
                      class="digitalNewPassword mb-5 mb-md-0"
                      :append-icon="showNewPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                      :type="showNewPassword ? 'text' : 'password'"
                      @click:append="showNewPassword = !showNewPassword"
                      v-model="newDigitalPassword"
                      :rules="digitalNewPassword"
                      label="Nova senha"
                      v-mask="'######'"
                      placeholder="Digite aqui sua nova senha"
                      required
                    ></v-text-field>
                    <v-text-field
                      class="digitalConfirmPassword mb-5 mb-md-0"
                      v-model="confirmDigitalPassword"
                      :append-icon="showConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      @click:append="showConfirmPassword = !showConfirmPassword"
                      :rules="digitalConfirmPassword"
                      v-mask="'######'"
                      label="Confirmar senha"
                      placeholder="Preencher igual ao campo acima"
                      required
                    ></v-text-field>
                  </v-row>
                </v-col>
                  <v-row justify="end">
                    <v-col cols="12" class="button-account pl-0 pr-0"
                      v-if="$vuetify.breakpoint.smAndDown">
                      <v-divider class="mb-2"></v-divider>
                      <v-col cols="12">
                        <v-btn class="btn-home" :disabled="!valid" rounded color="#8C8C8C"
                          height="50" block outlined @click="backHome">Voltar para Home</v-btn>
                      </v-col>
                      <v-divider class="mt-2"></v-divider>
                    </v-col>
                    <v-col md="7" cols="12" class="button-account">
                      <v-btn class="btn-reset" :disabled="!valid"
                      rounded color="#D70472" block  @click="validate">Salvar Senha</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="modal-form mt-12 mt-md-0" v-if="getPasswordCreated">
          <v-row justify="center" class="success-text" >
              <v-col cols="12" class="mt-12">
                <v-row justify="center">
                  <img class="imgSuccess mt-md-5 mt-12" src="@/assets/successPink.png" />
                </v-row>
              </v-col>
              <v-col cols="12" class="mb-12 mb-md-0">
                <v-row justify="center">
                  <v-col cols="10" md="8" class="mb-8 mb-md-0">
                    <p class="text-success mb-0">Senha digital criada com sucesso!</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mt-12 pa-0">
                <v-row justify="center">
                  <v-col cols="12" class="mt-12 pa-0">
                    <v-divider class="mb-5"></v-divider>
                    <v-col cols="12">
                      <v-btn class="btn-home" :disabled="!valid" rounded color="#8C8C8C"
                        height="50" block outlined @click="backHome">Voltar para Home</v-btn>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';

export default {
  directives: { mask },
  data() {
    return {
      showModal: true,
      dialog: true,
      valid: true,
      loadingBtn: false,
      showNewPassword: false,
      showConfirmPassword: false,
      password: '',
      newDigitalPassword: '',
      confirmDigitalPassword: '',
      digitalNewPassword: [
        valueInput => !!valueInput || 'Senha obrigatória',
        valueInput => (valueInput && valueInput.length === 6)
          || 'Senha deve ter 6 dígitos',
        valueInput => (valueInput && valueInput.match(/^\d+$/))
          || 'Senha deve ter somente números',
        valueInput => (valueInput && valueInput.match(/^(?!.*?(\w)\1{5}).*$/gm))
          || 'Senha deve ter números diferentes',
        valueInput => (valueInput && ('0123456789012345789').indexOf(valueInput) === -1)
          || 'Senha deve ter números não sequenciais',
      ],
      digitalConfirmPassword: [
        valueInput => !!valueInput || 'Senha de confirmação obrigatória',
        () => this.newDigitalPassword === this.confirmDigitalPassword
          || 'Senhas devem ser iguais',
      ],
    };
  },
  computed: {
    ...mapGetters({
      getPasswordCreated: 'DigitalAccount/getPasswordCreated',
      getPasswordFail: 'DigitalAccount/getPasswordFail',
    }),
    role() {
      return this.$store.getters['Auth/getRole'];
    },
  },
  methods: {
    ...mapActions({
      setPassword: 'DigitalAccount/createPassword',
    }),
    ...mapMutations({
      setShowPasswordModal: 'DigitalAccount/SET_SHOW_PASSWORD_MODAL',
    }),
    validate() {
      this.$refs.form.validate();
      if (this.newDigitalPassword && this.confirmDigitalPassword
      && this.newDigitalPassword === this.confirmDigitalPassword) {
        this.$refs.form.validate();
        this.setPassword({
          password: this.newDigitalPassword,
        });
      }
    },
    async closeModal() {
      this.dialog = false;
      this.showModal = false;
      this.setShowPasswordModal(false);
    },
    backHome() {
      this.closeModal();
      const home = 'home';
      return this.$router.push({ path: `/customer/${home}` });
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.closeModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/commons/modalHomePage.scss';
</style>
